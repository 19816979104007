import { ofType } from 'redux-observable';
import { of, EMPTY, merge } from 'rxjs';
import { mergeMap, map, filter, take, takeUntil } from 'rxjs/operators';
import { CheckoutPresets } from 'behavior/settings/constants';
import { APP_INIT_HYDRATE, APP_HYDRATED } from 'behavior/app';
import { skipIfPreview } from 'behavior/preview';
import { NAVIGATION_REQUESTED } from 'behavior/events';
import { replaceTo } from 'behavior/routing';
import { RouteName } from 'routes';
import { CHECKOUT_INFO_UPDATED, checkoutInfoUpdated } from './actions';
import {
  getFirstIncompleteStepBeforeCurrent,
  getStepNavigationInfo,
  getStepByUrlHash,
} from './helpers';
import { Steps } from './constants';

export default function multiStepEpic(action$, state$, { scope }) {
  if (scope === 'SERVER')
    return EMPTY;

  const redirectToInvalidStep$ = action$.pipe(
    ofType(APP_INIT_HYDRATE, CHECKOUT_INFO_UPDATED),
    filter(_ => isMultiStepCheckout(state$)),
    skipIfPreview(state$),
    mergeMap(action => {
      const currentStep = getStepByUrlHash(state$.value.routing.location.hash);
      if (currentStep) {
        const info = state$.value.page.info;
        const isInit = action.type === APP_INIT_HYDRATE;
        const steps = isInit ? info.steps : action.payload.steps;

        if (steps) {
          let stepToRedirect = getFirstIncompleteStepBeforeCurrent(steps, currentStep);
          if (!stepToRedirect && !steps.some(s => s.id === currentStep))
            stepToRedirect = { id: Steps.Overview, isCompleted: true };

          if (stepToRedirect) {
            const navInfo = getStepNavigationInfo(stepToRedirect, state$.value.routing.location, !!info.quote, !!info.isQuote, !!info.isGuest);
            navInfo.to.options.omitRefreshing = isInit;
            return of(replaceTo(navInfo.url, navInfo.to));
          }
        }
      }

      return EMPTY;
    }),
  );

  const setCurrentStepOnHydrated$ = action$.pipe(
    ofType(APP_HYDRATED),
    take(1),
    takeUntil(action$.pipe(ofType(NAVIGATION_REQUESTED))),
    filter(_ => {
      const info = state$.value.page.info;
      return isMultiStepCheckout(state$) && info?.currentStep === Steps.None;
    }),
    map(_ => checkoutInfoUpdated({ currentStep: getStepByUrlHash(state$.value.routing.location.hash) })),
  );

  return merge(
    redirectToInvalidStep$,
    setCurrentStepOnHydrated$,
  );
}

function isMultiStepCheckout(state$) {
  const routeName = state$.value.routing.routeData.routeName;
  const pagePreset = state$.value.settings.checkout.pagePreset;
  return routeName === RouteName.Checkout && pagePreset === CheckoutPresets.MultiStep;
}
