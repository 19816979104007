import type { AppState } from 'behavior';
import type { StoreDependencies } from 'behavior/types';
import type { StateObservable } from 'redux-observable';
import type { ProductGroupPage, ProductGroupPageResponse } from './types';

import { of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { PageComponentNames } from '../componentNames';
import { productGroupPageQuery } from './queries';
import { areAnalyticsSettingsLoaded } from 'behavior/analytics';
import { requestCalculatedFields } from './actions';
import { ProductMediaType } from '../product';
import { parseVideoData, VideoProviderType } from 'utils/video';
import { getBackTo } from '../helpers';
import { RouteName } from 'routes';

type Params = {
  params: {
    id: string;
    language: number;
  };
};

export default ({ params: { id, language } }: Params, state$: StateObservable<AppState>, { api }: StoreDependencies) => state$.pipe(
  first(areAnalyticsSettingsLoaded),
  switchMap(({ settings, analytics }) => {
    if (!settings.product.productGrouping.isEnabled)
      return of(null);

    const loadCategories = analytics.isTrackingEnabled;
    return api.graphApi<ProductGroupPageResponse>(productGroupPageQuery, { groupId: id, loadCategories }).pipe(
      map(({ pages: { productGroupDetails } }) => {
        if (!productGroupDetails)
          return null;

        const component = PageComponentNames.ProductGroup;
        const productGroup = productGroupDetails.productGroup;
        const page: ProductGroupPage = {
          ...productGroup,
          media: processMedia(productGroup.title, productGroup.media),
          component,
          backTo: getBackTo(state$, [
            RouteName.ProductGroupPage,
          ], language),
        };

        if (productGroup.products.length === 0)
          return { page };

        const productsCountPerBatch = settings.productList.listProductAmount;
        const ids = productGroup.products.map(p => p.id);

        return { page, action$: of(requestCalculatedFields(id, { ids, productsCountPerBatch })) };
      }),
    );
  }),
);

const processMedia = (pageTitle: string, media?: Media): ProcessedMedia | undefined => {
  if (!media)
    return undefined;

  const results: ProcessedMedia = [];

  media.forEach(item => {
    switch (item.type) {
      case ProductMediaType.Video:
        const videoData = parseVideoData(item.url);

        if (!videoData)
          break;

        results.push({
          type: item.type,
          videoData,
          title: pageTitle,
        });
        break;

      case ProductMediaType.Image:
        if (item.small || item.medium || item.large) {
          if (item.title) {
            results.push(item as ProcessedImage);
          } else {
            results.push({
              ...item,
              title: pageTitle,
            });
          }
        }
        break;

      default:
        return;
    }
  });

  return results;
};

type Media = Array<{
  type: ProductMediaType.Image;
  small: string | null;
  medium: string | null;
  large: string | null;
  title: string | null;
} | {
  type: ProductMediaType.Video;
  url: string;
}> | null;

type ProcessedImage = {
  type: ProductMediaType.Image;
  small: string | null;
  medium: string | null;
  large: string | null;
  title: string;
};

type ProcessedMedia = Array<ProcessedImage | {
  type: ProductMediaType.Video;
  videoData: {
    provider: VideoProviderType;
    videoId: string;
  };
  title: string;
}>;
