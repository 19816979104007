import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import {
  checkoutInfoUpdated,
  COSTCENTER_SELECT,  
  notifyCostCenterSelected,  
} from './actions';
import { switchMap, mergeMap, tap } from 'rxjs/operators';
import { retryWithToast  } from 'behavior/errorHandling';
import {
  getSelectCostCenterMutation,  
} from './queries';
import { navigateOnIncorrect } from './helpers';
import { skipIfPreview } from 'behavior/preview';

export default function createEpic(waitForSubmit) {
  return function (action$, state$, { api, logger }) {
    const isQuote = () => state$.value.page.info?.isQuote || false;
    const isPromotion = () => !!state$.value.page.info?.quote;    

    const selectCostCenter$ = action$.pipe(
      ofType(COSTCENTER_SELECT),
      skipIfPreview(state$),      
      switchMap(({ payload }) => waitForSubmit(() => api.graphApi(getSelectCostCenterMutation(isPromotion()), {
        id: payload.costCenterInfo.id,
        name: payload.costCenterInfo.name,
        claveCliente: payload.costCenterInfo.claveCliente,
        claveCC: payload.costCenterInfo.claveCC,
        descripcion: payload.costCenterInfo.descripcion,
        montoPorPeriodo: payload.costCenterInfo.montoPorPeriodo,
        saldoCentroDeCosto: payload.costCenterInfo.saldoCentroDeCosto,
        asQuote: isQuote(),
        maxLines: state$.value.settings.checkout.maxOverviewLines + 1,
      }).pipe(
        mergeMap(({ checkout }) => {
          if (checkout) {
            const selectResult = checkout.costCenter.select;                       
             
            if (selectResult.success){
              notifyCostCenterSelected(selectResult.info.costCenter.id, selectResult.info);
              return of(checkoutInfoUpdated(selectResult.info));
            }

            return of();
          }
          return of(navigateOnIncorrect(state$.value.page.info));
          // navigateOnIncorrect(state$.value.page.info);
        }),
        retryWithToast(action$, logger),
      ))),
    );    
    return merge(selectCostCenter$);
  };
}